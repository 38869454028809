import React from "react";
import Helmet from "react-helmet";

const WelcomeToArcRedirect = () => (
  <div>
    <Helmet>
      <meta
        http-equiv="refresh"
        content="0;URL='https://arc.net/welcome-to-arc'"
      />
      <meta name="robots" content="noindex" />
    </Helmet>
  </div>
);

export default WelcomeToArcRedirect;
